import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { formatValue } from "../utils/formatValue";

export const RenderPdfReceitaPorFonteRec = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  pdfName = "Natureza da Receita por Fontes ou Destinações de Recursos"
) => {
  const colNames = ["CÓDIGO", "ESPECIFICAÇÕES", "VALOR"];
  const styledCol = [
    { flexBasis: "70px", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "120px", flexShrink: 1, flexGrow: 1, textAlign: "right" },
  ];

  const styledCol2 = [
    {
      flexBasis: "70px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "right",
      padding: "1px 0",
    },
    {
      flexBasis: "100%",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "left",
      padding: "1px 0",
    },
    {
      flexBasis: "120px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "right",
      padding: "1px 0",
    },
  ];

  const titles = [
    "recursos livres (nao vinculados)",
    "recursos vinculados a educacao",
    "recursos vinculados a saude",
    "recursos vinculados a assistencia social",
    "demais vinculacoes decorrentes de transferencias",
    "demais vinculacoes legais",
    "recursos vinculados a previdencia social",
    "recursos extraorcamentarios",
    "outras vinculacoes",
    "valores nao identificados",
    "contribuicao para a formacao do fundeb - deducao",
  ];
  const bolder = [];

  const normalizeString = (str) => {
    if (str) {
      const lowerStr = str.toLowerCase();
      const noAccents = lowerStr
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const trimmed = noAccents.trim();
      const singleSpaced = trimmed.replace(/\s+/g, " ");

      return singleSpaced.endsWith(".")
        ? singleSpaced.slice(0, -1)
        : singleSpaced;
    }
  };

  console.log("ESSES SÂO MEUS DADOS: ", dados);

  return (
    <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={pdfName}
        plusText={" "}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
      />
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
        fixed
      >
        <Text>Portaria nº 710, de 25 de fevereiro de 2021</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={stylesPdf.colunas} fixed>
        {opcoes[4]?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index]}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          {dados.data.map((item, index) => {
            const isTitle1 = titles.some((string) =>
              normalizeString(item.especificacao).includes(string)
            );
            const isBolder1 = bolder.includes(
              normalizeString(item.especificacao)
            );
            return (
              <React.Fragment key={index}>
                <View
                  style={[
                    stylesPdf.colunas,
                    {
                      gap: 0,
                      backgroundColor: isTitle1 ? "#a6a6a6" : "transparent",
                      fontWeight: isBolder1 || isTitle1 ? "bold" : "normal",
                    },
                  ]}
                >
                  {opcoes[4]?.checkBoxes[0].selected && (
                    <PdfInfos
                      pdfInfo={item.codigo || ""}
                      styledCol={[styledCol2[0]]}
                    />
                  )}
                  {opcoes[4]?.checkBoxes[1].selected && (
                    <PdfInfos
                      pdfInfo={item.especificacao || ""}
                      styledCol={[styledCol2[1]]}
                    />
                  )}
                  {opcoes[4]?.checkBoxes[2].selected && (
                    <PdfInfos
                      pdfInfo={formatValue(item.valor) || ""}
                      styledCol={[styledCol2[2]]}
                    />
                  )}
                </View>
                <View
                  style={{
                    height: "1px",
                    width: "100%",
                    backgroundColor: "#a6a6a6",
                  }}
                ></View>
              </React.Fragment>
            );
          })}
          <View
            style={[
              stylesPdf.divInferiorColuna,
              {
                fontWeight: "bold",
                fontSize: 10,
                textTransform: "uppercase",
                color: "white",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              },
            ]}
          >
            <Text>TOTAL</Text>
            <Text>{formatValue(dados.sum)}</Text>
          </View>
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
