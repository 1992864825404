import { Container } from "react-bootstrap";
import GlobalStyles from "../../../../styles/StyledComponents/GlobalStyles";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { PagRelatorio } from "./components/PagRelatorio";
import { useContext, useEffect, useState } from "react";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { AllRelatorios } from "./PDF/AllRelatorios";
import { ToastContainer } from "react-toastify";
import { useGenerateRelatorio } from "../../../../hooks/useGenerateRelatorio";
import { CurrentCityContext } from "../../../../contexts/CurrentCityContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { getDataLoa } from "./utils/Loa";
const dataLoa = [
  {
    id: 1,
    titulo:
      "Anexo I - Demonstração da Receita e Despesa segundo as Categorias Econômicas",
  },
  {
    id: 2,
    titulo: "Anexo II - Receita Segundo a Categoria Econômica",
  },
  {
    id: 3,
    titulo: "Anexo II - Receita Segundo as Categorias Econômicas por Órgão",
  },
  {
    id: 4,
    titulo: "Natureza da Receita por Esfera",
  },
  {
    id: 5,
    titulo: "Natureza da Receita por Fontes ou Destinações de Recursos",
  },
  {
    id: 6,
    titulo: "Quadro Detalhado da Receita - QDR",
  },
  {
    id: 7,
    titulo: "Anexo II - Despesa Segundo as Categorias Econômicas",
  },
  {
    id: 8,
    titulo: "Anexo II - Despesa Segundo as Categorias Econômicas - Órgão",
  },
  {
    id: 9,
    titulo: "Natureza da Despesa por Esfera",
  },
  {
    id: 10,
    titulo: "Natureza da Despesa por Fontes ou Destinações de Recursos",
  },
  {
    id: 11,
    titulo: "Quadro Detalhado da Despesa - QDD por Elemento de Despesa",
  },
  {
    id: 12,
    titulo: "Quadro Detalhado da Despesa - QDD por Modalidade de Aplicação",
  },
];

let reportIds = [];

export const Relatorios = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { currentCity } = useContext(CurrentCityContext);
  const { userAuthData } = useContext(AuthContext);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState([
      {
        opcao: "Exibir",
        checkBoxes: [
          {
            label: "Código",
            value: "codigo",
            selected: true,
          },
          {
            label: "Descrição",
            value: "descricao",
            selected: true,
          },
        ],
      },
      {
        opcao: "Exibir",
        checkBoxes: [
          {
            label: "Código",
            value: "codigo",
            selected: true,
          },
          {
            label: "Especificações",
            value: "especificacoes",
            selected: true,
          },
          {
            label: "Desdobramento",
            value: "desdobramento",
            selected: true,
          },
          {
            label: "Fonte",
            value: "fonte",
            selected: true,
          },
          {
            label: "Categoria Econômica",
            value: "categoria economica",
            selected: true,
          },
        ],
      },
      {
        opcao: "Exibir",
        checkBoxes: [
          {
            label: "Código",
            value: "codigo",
            selected: true,
          },
          {
            label: "Especificações",
            value: "especificacoes",
            selected: true,
          },
          {
            label: "Desdobramento",
            value: "desdobramento",
            selected: true,
          },
          {
            label: "Fonte",
            value: "fonte",
            selected: true,
          },
          {
            label: "Categoria Econômica",
            value: "categoria economica",
            selected: true,
          },
        ],
      },
      {
        opcao: "Natureza da Receita por Esfera",
        checkBoxes: [
          {
            label: "Código",
            value: "codigo",
            selected: true,
          },
          {
            label: "Especificações",
            value: "especificacoes",
            selected: true,
          },
          {
            label: "Fiscal",
            value: "fiscal",
            selected: true,
          },
          {
            label: "Seguridade",
            value: "seguridade",
            selected: true,
          },
        ],
      },
      {
        opcao: "Exibir",
        checkBoxes: [
          {
            label: "Código",
            value: "codigo",
            selected: true,
          },
          {
            label: "Especificacao",
            value: "descricao",
            selected: true,
          },
          {
            label: "Valor",
            value: "valor",
            selected: true,
          },
        ],
      },
      {
        opcao: "Quadro Detalhado da Receita - QDR",
        checkBoxes: [
          {
            label: "Código",
            value: "codigo",
            selected: true,
          },
          {
            label: "Especificações",
            value: "especificacoes",
            selected: true,
          },
          {
            label: "Total",
            value: "total",
            selected: true,
          },
        ],
      },
      {
        opcao: "Despesa Segundo as Categorias Econômicas - Órgão",
        checkBoxes: [
          {
            label: "Código",
            value: "codigo",
            selected: true,
          },
          {
            label: "Especificações",
            value: "especificacoes",
            selected: true,
          },
          {
            label: "Desdobramento",
            value: "desdobramento",
            selected: true,
          },
          {
            label: "Grupo da Natureza",
            value: "grupoNatureza",
            selected: true,
          },
          {
            label: "Categoria Econômica",
            value: "categoriaEconomica",
            selected: true,
          },
        ],
      },
      {
        opcao: "Quadro Detalhado da Despesa - QDD",
        checkBoxes: [
          {
            label: "Código",
            value: "codigo",
            selected: true,
          },
          {
            label: "Especificações",
            value: "especificacoes",
            selected: true,
          },
          {
            label: "Esfera",
            value: "esfera",
            selected: true,
          },
          {
            label: "Total",
            value: "total",
            selected: true,
          },
        ],
      },
    ]);
  const [entidadesGestorasDoCliente, setEntidadesGestorasDoCliente] = useState(
    []
  );

  const { handleGeneratePdf, pdfData } = useGenerateRelatorio(
    selectBoxesOptionsAbaOptions,
    selectBoxesOptionsAbaPrincipal,
    currentClient
  );

  const [allData, setAllData] = useState({
    receitaDespesaCEconomica: null,
    receitaSegCEconomica: null,
    receitasCatEconOrgao: null,
    receitasEsferas: null,
    receitaPorFonteRec: null,
    receitaQdr: null,
    despesaPorFonteRec: null,
    despesaCatEconOrgao: null,
    despesaQdd: null,
    qddAplicacao: null,
  });

  useEffect(() => {
    if (currentCity && Object.keys(userAuthData).length > 0) {
      const entidades = userAuthData.clientes.filter(
        (item) =>
          item.endereco.municipio === currentCity.municipio &&
          item.endereco.estado === currentCity.estado
      );

      const formattedEntidades = [
        {
          opcao: "selecionado",
          checkBoxes: entidades.map((item) => ({
            dados: item,
            selected: true,
          })),
        },
      ];

      setEntidadesGestorasDoCliente(formattedEntidades);
    }
  }, [currentCity, userAuthData]);

  useEffect(() => {
    const endReports = [1, 6, 12];
    if (
      dataLoa &&
      !selectBoxesOptionsAbaPrincipal.some(
        (item) => item.opcao === "LOA - Lei Orçamentária Anual"
      )
    ) {
      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "LOA - Lei Orçamentária Anual",
          checkBoxes: dataLoa.map((item) => ({
            label: `${item.titulo}`,
            value: item.id,
            selected: false,
            style: endReports.includes(item.id)
              ? { borderBottom: "2px solid #f2f3f8", margin: "-3px 0" }
              : { borderBottom: "none", margin: "-3px 0" },
          })),
        },
      ]);
    }
  }, [selectBoxesOptionsAbaPrincipal]);

  useEffect(() => {
    let selecteds = [];

    selectBoxesOptionsAbaPrincipal[0]?.checkBoxes?.forEach((item) => {
      if (item.selected) {
        selecteds.push(item.value);
      }
    });
    reportIds = selecteds;
  }, [selectBoxesOptionsAbaPrincipal]);

  const getEntidadeGestora = () => {
    const entidades = [].concat(
      Object.entries(entidadesGestorasDoCliente[0].checkBoxes)
        .map(([key, value]) => {
          return value.selected ? value.dados.clienteId : null;
        })
        .filter((id) => id !== null)
    );
    return entidades;
  };

  const getDadosAllRelatoriosUnique = async () => {
    const newAllData = { ...allData };
    const clientes = getEntidadeGestora();

    await Promise.all([
      ReceitaSegCEconomica(newAllData, clientes),
      ReceitaDespesaCEconomica(newAllData, clientes),
      ReceitaCatEconOrgao(newAllData, clientes),
      ReceitaEsfera(newAllData, clientes),
      ReceitaPorFonteRec(newAllData, clientes),
      ReceitaQdr(newAllData, clientes),
      DespesaCatEconOrgao(newAllData, clientes),
      DespesaQdd(newAllData, clientes),
      DespesaSegCEconomica(newAllData, clientes),
      despesaPorFonteRec(newAllData, clientes),
      quadroDetalhadoQDDAplicacao(newAllData, clientes),
    ]);

    setAllData(newAllData);

    const getUniqueName = (reportIds, dataLoa) => {
      return reportIds
        .map((id) => {
          const found = dataLoa.find((item) => item.id === id);
          return found ? found.titulo : null;
        })
        .filter(Boolean);
    };

    return {
      reportIds,
      data: newAllData,
      uniqueName: getUniqueName(reportIds, dataLoa),
    };
  };

  const ReceitaDespesaCEconomica = async (newAllData) => {
    if (!reportIds.includes(1)) {
      newAllData.receitaDespesaCEconomica = null;
      return;
    }
    const result = await getDataLoa.getReceitaDespesaCEconomica(
      currentClient.clienteId
    );
    newAllData.receitaDespesaCEconomica = result;
  };

  const ReceitaSegCEconomica = async (newAllData) => {
    if (!reportIds.includes(2)) {
      newAllData.receitaSegCEconomica = null;
      return;
    }
    const result = await getDataLoa.getReceitaSegCEconomica(
      currentClient.clienteId
    );
    newAllData.receitaSegCEconomica = result;
  };

  const ReceitaCatEconOrgao = async (newAllData) => {
    if (!reportIds.includes(3)) {
      newAllData.receitasCatEconOrgao = null;
      return;
    }
    const result = await getDataLoa.getReceitasCatEconOrgao(
      currentClient.clienteId
    );
    newAllData.receitasCatEconOrgao = result;
  };

  const ReceitaEsfera = async (newAllData) => {
    if (!reportIds.includes(4)) {
      newAllData.receitasCatEconOrgao = null;
      return;
    }
    const result = await getDataLoa.getReceitasEsfera(currentClient.clienteId);
    newAllData.receitasEsferas = result;
  };

  const ReceitaPorFonteRec = async (newAllData) => {
    if (!reportIds.includes(5)) {
      newAllData.receitaPorFonteRec = null;
      return;
    }
    const result = await getDataLoa.getReceitaPorFonteRec(
      currentClient.clienteId
    );
    newAllData.receitaPorFonteRec = result;
  };
  const ReceitaQdr = async (newAllData) => {
    if (!reportIds.includes(6)) {
      newAllData.receitaQdr = null;
      return;
    }
    const result = await getDataLoa.getReceitasQdr(currentClient.clienteId);
    newAllData.receitaQdr = result;
  };
  const DespesaSegCEconomica = async (newAllData, clientes) => {
    if (!reportIds.includes(7)) {
      newAllData.despesaSegCEconomica = null;
      return;
    }
    const result = await getDataLoa.getDespesaSegCEconomica(
      clientes.length > 0 ? clientes : currentClient.clienteId
    );
    newAllData.despesaSegCEconomica = result;
  };
  const despesaPorFonteRec = async (newAllData) => {
    if (!reportIds.includes(10)) {
      newAllData.despesaPorFonteRec = null;
      return;
    }
    const result = await getDataLoa.getDespesaPorFonteRec(
      currentClient.clienteId
    );
    newAllData.despesaPorFonteRec = result;
  };
  const quadroDetalhadoQDDAplicacao = async (newAllData) => {
    if (!reportIds.includes(12)) {
      newAllData.despesaSegCEconomica = null;
      return;
    }
    const result = await getDataLoa.getQuadroDetalhadoQDDAplicacao(
      currentClient.clienteId
    );
    newAllData.qddAplicacao = result;
  };

  const DespesaCatEconOrgao = async (newAllData) => {
    if (!reportIds.includes(8)) {
      newAllData.despesaCatEconOrgao = null;
      return;
    }
    const result = await getDataLoa.getDespesaCatEconOrgao(
      currentClient.clienteId
    );
    newAllData.despesaCatEconOrgao = result;
  };

  const DespesaQdd = async (newAllData) => {
    if (!reportIds.includes(11)) {
      newAllData.despesaQdd = null;
      return;
    }
    const result = await getDataLoa.getDespesaQdd(currentClient.clienteId);
    newAllData.despesaQdd = result;
  };

  const handleGeneratePDFs = async (action) => {
    const allIds = [...reportIds];
    reportIds = [];

    for (const id of allIds) {
      reportIds.push(id);
      await handleGeneratePdf(
        AllRelatorios,
        getDadosAllRelatoriosUnique,
        action
      );
      reportIds.pop();
    }
    reportIds = [...allIds];
  };

  return (
    <>
      <GlobalStyles orientation={"portrait"} />

      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <HeaderCadastros
          hideNavButtons
          NomePaginaTitulo={"Impressos"}
          PaginaSubtitulo={"Relatórios"}
          OpcoesDeArquivo={true}
          ButtonPDF={(action) => {
            handleGeneratePDFs(action);
          }}
          PdfData={pdfData}
        />
        <ToastContainer />
        <PagRelatorio
          activeMonth={true}
          entidadesGestorasDoCliente={entidadesGestorasDoCliente}
          setEntidadesGestorasDoCliente={setEntidadesGestorasDoCliente}
          principalOptions={[
            {
              label: "LOA - Lei Orçamentária Anual",
              value: "LOA - Lei Orçamentária Anual",
            },
            {
              label: "LDO - Lei de Diretrizes Orçamentárias",
              value: "LDO - Lei de Diretrizes Orçamentárias",
            },
            {
              label: "PPA - Plano Plurianual",
              value: "PPA - Plano Plurianual",
            },
          ]}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
        />
        <div className="p-3"></div>
      </Container>
    </>
  );
};

// const getDadosAllRelatorios = async () => {
//   const newAllData = { ...allData };

//   await Promise.all([
//     ReceitaSegCEconomica(newAllData),
//     ReceitaDespesaCEconomica(newAllData),
//     ReceitaCatEconOrgao(newAllData),
//     ReceitaEsfera(newAllData),
//     ReceitaPorFonteRec(newAllData),
//   ]);

//   setAllData(newAllData);

//   return {
//     reportIds,
//     data: newAllData,
//   };
// };
