import { Document } from "@react-pdf/renderer";
import React from "react";
import { RenderPdfReceitaDespesaCEconomica } from "./RenderPdfReceitaDespesaCEconomica";
import { RenderPdfReceitaSegCEconomica } from "./RenderPdfReceitaSegCEconomica";
import { RenderPdfReceitaCatEconOrgao } from "./RenderPdfReceitaCatEconOrgao";
import { RenderPdfReceitaEsfera } from "./RenderPdfReceitaEsfera";
import { RenderPdfReceitaPorFonteRec } from "./RenderPdfReceitaPorFonteRec";
import { RenderPdfReceitaQdr } from "./RenderPdfReceitaQdr";
import { RenderPdfDespesaCatEconOrgao } from "./RenderPdfDespesaCatEconOrgao";
import { RenderPdfDespesaQdd } from "./RenderPdfDespesaQdd";
import { RenderPdfQDDAplicacao } from "./RenderPdfQDDAplicacao";

export const AllRelatorios = ({ pdfContent }) => {
  const {
    dadosRelatorio,
    opcoes,
    userData: { currentClient, userAuthData, dataClientInfos },
    currentYear,
    selectBoxesOptionsAbaPrincipal,
  } = pdfContent;

  let nameTitleDocumentPdf = "Lei Orçamentária Anual";

  if (!dadosRelatorio) return null;

  const optionsToCheck = [
    "LOA - Lei Orçamentária Anual",
    "LDO - Lei de Diretrizes Orçamentárias",
    "PPA - Plano Plurianual",
  ];

  for (const optionKey of optionsToCheck) {
    const selectedOption = selectBoxesOptionsAbaPrincipal.find(
      (option) => option.opcao === optionKey
    );

    if (selectedOption) {
      const selectedCheckBox = selectedOption.checkBoxes.filter(
        (checkBox) => checkBox.selected
      );

      if (selectedCheckBox.length === 1) {
        nameTitleDocumentPdf = selectedCheckBox[0].label;
        break;
      }
    }
  }

  return (
    <Document
      title={
        dadosRelatorio.uniqueName[0]
          ? dadosRelatorio.uniqueName[0]
          : nameTitleDocumentPdf
      }
    >
      {dadosRelatorio.reportIds.includes(1) &&
        dadosRelatorio.data.receitaDespesaCEconomica &&
        RenderPdfReceitaDespesaCEconomica(
          dadosRelatorio.data.receitaDespesaCEconomica,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear
        )}
      {dadosRelatorio.reportIds.includes(2) &&
        dadosRelatorio.data.receitaSegCEconomica &&
        RenderPdfReceitaSegCEconomica(
          dadosRelatorio.data.receitaSegCEconomica,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          "Despesa Segundo a Categoria Econômica"
        )}
      {dadosRelatorio.reportIds.includes(3) &&
        dadosRelatorio.data.receitasCatEconOrgao &&
        RenderPdfReceitaCatEconOrgao(
          dadosRelatorio.data.receitasCatEconOrgao,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear
        )}
      {dadosRelatorio.reportIds.includes(4) &&
        dadosRelatorio.data.receitasEsferas &&
        RenderPdfReceitaEsfera(
          dadosRelatorio.data.receitasEsferas,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear
        )}
      {dadosRelatorio.reportIds.includes(5) &&
        dadosRelatorio.data.receitaPorFonteRec &&
        RenderPdfReceitaPorFonteRec(
          dadosRelatorio.data.receitaPorFonteRec,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear
        )}
      {dadosRelatorio.reportIds.includes(6) &&
        dadosRelatorio.data.receitaQdr &&
        RenderPdfReceitaQdr(
          dadosRelatorio.data.receitaQdr,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear
        )}
      {dadosRelatorio.reportIds.includes(7) &&
        dadosRelatorio.data.despesaSegCEconomica &&
        RenderPdfReceitaSegCEconomica(
          dadosRelatorio.data.despesaSegCEconomica,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          true
        )}
      {dadosRelatorio.reportIds.includes(8) &&
        dadosRelatorio.data.despesaCatEconOrgao &&
        RenderPdfDespesaCatEconOrgao(
          dadosRelatorio.data.despesaCatEconOrgao,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear
        )}
      {dadosRelatorio.reportIds.includes(10) &&
        dadosRelatorio.data.despesaPorFonteRec &&
        //RenderPDFDespesaPorFonteRec - Reaproveitado de Receita
        RenderPdfReceitaPorFonteRec(
          dadosRelatorio.data.despesaPorFonteRec,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear,
          "Natureza da Despesa por Fontes ou Destinações de Recursos"
        )}
      {dadosRelatorio.reportIds.includes(11) &&
        dadosRelatorio.data.despesaQdd &&
        RenderPdfDespesaQdd(
          dadosRelatorio.data.despesaQdd,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear
        )}
      {dadosRelatorio.reportIds.includes(12) &&
        dadosRelatorio.data.qddAplicacao &&
        RenderPdfQDDAplicacao(
          dadosRelatorio.data.qddAplicacao,
          opcoes,
          currentClient,
          userAuthData,
          dataClientInfos,
          currentYear
        )}
    </Document>
  );
};
