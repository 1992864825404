import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { formatValue } from "../utils/formatValue";

export const RenderPdfReceitaSegCEconomica = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear,
  nameRelatorio = "Receita Segundo a Categoria Econômica"
) => {
  const colNames = [
    "CÓDIGO",
    "ESPECIFICAÇÕES",
    "DESDOBRAMENTO",
    "FONTE",
    "CATEGORIA ECONÔMICA",
  ];
  const styledCol = [
    { flexBasis: "150px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: "225px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "left",
    },
    {
      flexBasis: "160px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "160px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];
  const titles =
    nameRelatorio === "Receita Segundo a Categoria Econômica"
      ? [
          "receitas correntes",
          "receitas de capital",
          "contribuicao para formacao do fundeb - deducao",
        ]
      : ["despesas correntes", "despesas de capital"];
  const bolderTest = (item) => {
    const patterns = [
      /^\d+\.0\.0\.0\.00\.0\.0$/, // "x.0.0.0.00.0.0"
      /^\d+\.\d+\.0\.0\.00\.0\.0$/, // "x.x.0.0.00.0.0"
      /^\d+\.\d{3}$/, // "x.xxx"
    ];
    return patterns.some((pattern) => pattern.test(item));
  };

  const normalizeString = (str) => {
    const lowerStr = str.toLowerCase();
    const noAccents = lowerStr.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const trimmed = noAccents.trim();
    const singleSpaced = trimmed.replace(/\s+/g, " ");

    return singleSpaced.endsWith(".")
      ? singleSpaced.slice(0, -1)
      : singleSpaced;
  };

  return (
    <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={nameRelatorio}
        plusText={"Anexo II"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
      />
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
        fixed
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={stylesPdf.colunas} fixed>
        {opcoes[1]?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index]}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          {dados?.data?.map((item, index) => {
            const isTitle = titles.includes(
              normalizeString(item?.especificacao)
            );
            const isBolder = bolderTest(item.codigo);
            return (
              <React.Fragment key={index}>
                <View
                  style={[
                    stylesPdf.colunas,
                    {
                      gap: 0,
                      backgroundColor: isTitle ? "#a6a6a6" : "transparent",
                      fontWeight: isTitle || isBolder ? "bold" : "normal",
                    },
                  ]}
                >
                  {opcoes[1]?.checkBoxes[0].selected && item.codigo !== "0" && (
                    <PdfInfos
                      pdfInfo={item.codigo || ""}
                      styledCol={[
                        styledCol[0],
                        { padding: "2px 0", textAlign: "right" },
                      ]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[1].selected && (
                    <PdfInfos
                      pdfInfo={item.especificacao || ""}
                      styledCol={[styledCol[1], { padding: "2px 0" }]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[2].selected && (
                    <PdfInfos
                      pdfInfo={formatValue(item.desdobramento) || ""}
                      styledCol={[
                        // styledCol[2],
                        {
                          padding: "2px 0",
                          textAlign: "right",
                          flexBasis: "160px",
                          flexShrink: 1,
                          flexGrow: 1,
                        },
                      ]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[3].selected && (
                    <PdfInfos
                      pdfInfo={formatValue(item.fonte) || ""}
                      styledCol={[
                        styledCol[3],
                        { padding: "2px 0", textAlign: "right" },
                      ]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[4].selected && (
                    <PdfInfos
                      pdfInfo={formatValue(item.catEcon) || ""}
                      styledCol={[
                        styledCol[4],
                        { padding: "2px 0", textAlign: "right" },
                      ]}
                    />
                  )}
                </View>
              </React.Fragment>
            );
          })}
          <View
            style={[
              stylesPdf.divInferiorColuna,
              {
                fontWeight: "bold",
                fontSize: 10,
                textTransform: "uppercase",
                color: "white",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              },
            ]}
          >
            <Text>TOTAL</Text>
            <Text>{formatValue(dados.sum)}</Text>
          </View>
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
