//import { calcularTotalReceitasComDeducao } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
import api from "../../../../../../utils/api";

export const getDespesaSegCEconomica = async (clienteId) => {
  console.log("clienteId: ", clienteId);
  try {
    const response = await api.get(
      `naturezaDaDespesa/relatorio/${clienteId}/2024`
    );
    const naturezaDaDespesa = response.data;
    console.log("response.data", naturezaDaDespesa);
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
};

const processAllData = (naturezaDaDespesa) => {
  let sumValues = 0;
  const processItem = (item, sum = "sum") => {
    const seccoes = item.codigo
      .split(".")
      .filter((secao) => parseInt(secao) !== 0);
    let desdobramento = null,
      fonte = null,
      catEcon = null;

    if (seccoes.length === 1) {
      catEcon = item.valor;
      if (sum === "reduce") {
        sumValues -= parseFloat(item.valor);
      } else {
        sumValues += parseFloat(item.valor);
      }
    } else if (seccoes.length === 2) {
      fonte = item.valor;
    } else if (seccoes.length > 2) {
      desdobramento = item.valor;
    }

    const resultado = [
      {
        codigo: item.codigo,
        especificacao: item.nome,
        desdobramento: desdobramento,
        fonte: fonte,
        catEcon: catEcon,
        valor: item.valor,
      },
    ];

    if (item.fontes && item.fontes.length > 0) {
      const recursos = [];
      item.fontes.forEach((recurso) => {
        const found = recursos.find((r) => r.codigo === recurso.conta);

        if (found) {
          found.desdobramento += parseFloat(recurso.valor);
        } else {
          recursos.push({
            codigo: recurso.conta,
            especificacao: recurso.tituloFonte,
            desdobramento: parseFloat(recurso.valor),
            fonte: null,
            catEcon: null,
          });
        }
      });
      const filteredRec = recursos.sort((a, b) => {
        if (a.codigo < b.codigo) return -1;
        if (a.codigo > b.codigo) return 1;
        return 0;
      });
      resultado.push(...filteredRec);
    }
    return resultado;
  };

  const getFilteredData = (userData) => {
    const data = userData.naturezasConsolidadas?.flatMap((item) =>
      processItem(item, "sum")
    );
    return data;
  };
  const dadosFiltrados = [].concat(
    ...Object.entries(naturezaDaDespesa?.contas?.relatorios).map(
      ([_, userData]) => {
        return getFilteredData(userData);
      }
    )
  );

  const somaFormatada = sumValues;
  return {
    data: dadosFiltrados,
    sum: somaFormatada,
  };
};
