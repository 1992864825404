import { calcularTotalReceitasComDeducao } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
import api from "../../../../../../utils/api";

const normalizeString = (str) => {
  if (str) {
    const lowerStr = str.toLowerCase();
    const noAccents = lowerStr.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const trimmed = noAccents.trim();
    const singleSpaced = trimmed.replace(/\s+/g, " ");

    return singleSpaced.endsWith(".")
      ? singleSpaced.slice(0, -1)
      : singleSpaced;
  }
};

export const getReceitaDespesaCEconomica = async (clienteId) => {
  try {
    const response1 = await api.get(`qdr/relatorio/${clienteId}/2024`);
    const response2 = await api.get(
      `/naturezaDaDespesa/relatorio/${clienteId}/2024`
    );
    console.log("RESPONSES: ", response1.data, response2.data);
    return processAllData(
      response1.data,
      response2.data.contas.relatorios[clienteId]
    );
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
};

export const processAllData = (dataQdrRelatorio, dataNatDespRelatorio) => {
  console.log("ENTREI AQUI COM: ", dataQdrRelatorio, dataNatDespRelatorio);
  const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
  const totalDeducao = calcularTotalReceitasComDeducao(
    { naturezasDeducao: dataQdrRelatorio.naturezasDeducao },
    codigosSectionRelatorio
  );
  let valorReceitasCorrentes = null;
  let valorDespesasCorrentes = null;
  let valorReceitasCapital = null;
  let valorDespesasCapital = null;

  const getReceita = () => {
    const part1 = () => {
      let encontrouReceitasDeCapital = false;
      const regexPermitidoNaturezas =
        /^(\d)\.(0)\.(0)\.(0)\.(00)\.(0)\.(0)$|^(\d)\.(\d)\.(0)\.(0)\.(00)\.(0)\.(0)$/;

      const data =
        dataQdrRelatorio?.naturezas
          ?.filter((item) => {
            if (encontrouReceitasDeCapital) return false;
            if (regexPermitidoNaturezas.test(item.nr)) {
              if (
                normalizeString(item.especificacao) === "receitas de capital"
              ) {
                encontrouReceitasDeCapital = true;
                return false;
              }
              if (valorReceitasCorrentes === null) {
                valorReceitasCorrentes = parseFloat(item.valor);
              }
              return true;
            }
            return false;
          })
          .map((item) => ({
            codigo: item.nr,
            title1: item.especificacao,
            title2: "",
            value1: parseFloat(item.valor),
            value2: "",
          })) || [];

      const filteredData = data.sort((a, b) => {
        if (a.codigo < b.codigo) return -1;
        if (a.codigo > b.codigo) return 1;
        return 0;
      });

      filteredData.push(
        {
          title1: "Dedução da Receita",
          title2: "",
          value1: totalDeducao,
          value2: "",
        },
        {
          title1: "Contribuição para Formação do FUNDEB",
          title2: "",
          value1: totalDeducao,
          value2: "",
        }
      );
      return filteredData;
    };

    // const part2 = () => {
    //   const regexPermitidoDeducao =
    //     /^([1-9]\d*)\.([1-9]\d*)\.([1-9]\d*)\.([1-9]\d*)\.(00)\.(0)\.(0)$/;

    //   const data =
    //     dataQdrRelatorio?.naturezasDeducao
    //       ?.filter((item) => regexPermitidoDeducao.test(item.nr))
    //       .map((item) => ({
    //         codigo: item.nr,
    //         title1: item.especificacao,
    //         title2: "",
    //         value1: parseFloat(item.valor),
    //         value2: "",
    //       })) || [];

    //   const filteredData = data.sort((a, b) => {
    //     if (a.codigo < b.codigo) return -1;
    //     if (a.codigo > b.codigo) return 1;
    //     return 0;
    //   });

    //   return filteredData;
    // };

    const part3 = () => {
      const regexPermitidoNaturezas =
        /^(\d)\.(0)\.(0)\.(0)\.(00)\.(0)\.(0)$|^(\d)\.(\d)\.(0)\.(0)\.(00)\.(0)\.(0)$/;

      let encontrouReceitasDeCapital = false;

      const data =
        dataQdrRelatorio?.naturezas
          ?.filter((item) => {
            const especificacaoNormalizada = normalizeString(
              item.especificacao
            );
            if (especificacaoNormalizada === "receitas de capital") {
              encontrouReceitasDeCapital = true;
              valorReceitasCapital = parseFloat(item.valor);
            }
            return (
              encontrouReceitasDeCapital &&
              regexPermitidoNaturezas.test(item.nr)
            );
          })
          .map((item) => ({
            codigo: item.nr,
            title1: item.especificacao,
            title2: "",
            value1: parseFloat(item.valor),
            value2: "",
          })) || [];

      const filteredData = data.sort((a, b) => {
        if (a.codigo < b.codigo) return -1;
        if (a.codigo > b.codigo) return 1;
        return 0;
      });

      if (!encontrouReceitasDeCapital) {
        filteredData.push({
          title1: "Receitas de Capital",
          title2: "",
          value1: "00",
          value2: "",
        });
      }

      return filteredData;
    };

    return [...part1() /*...part2(),*/, ...part3()];
  };

  const getDespesa = () => {
    const regexPermitidoDespesas = /^\d+\.\d+\.00\.00\.00$/;
    let capitalCodigoInicial = null;
    let despesasCapitalItem = null;
    let reservaCodigoInicial = null;

    const data =
      dataNatDespRelatorio?.naturezasConsolidadas
        ?.filter((item) => regexPermitidoDespesas.test(item.codigo))
        .map((item) => {
          const codigoInicial = item.codigo.split(".")[0];
          if (
            normalizeString(item.nome) === "despesas correntes" &&
            !valorDespesasCorrentes
          ) {
            valorDespesasCorrentes = parseFloat(item.valor);
          }

          if (normalizeString(item.nome) === "despesas de capital") {
            capitalCodigoInicial = codigoInicial;
            despesasCapitalItem = {
              codigo: item.codigo,
              title1: "",
              title2: item.nome,
              value1: "",
              value2: parseFloat(item.valor),
            };
            valorDespesasCapital = parseFloat(item.valor);
          }

          if (normalizeString(item.nome) === "reserva de contingencia") {
            reservaCodigoInicial = codigoInicial;
          }

          return {
            codigo: item.codigo,
            title1: "",
            title2: item.nome,
            value1: "",
            value2: parseFloat(item.valor),
          };
        }) || [];

    const filteredData = data.sort((a, b) => {
      if (a.codigo < b.codigo) return -1;
      if (a.codigo > b.codigo) return 1;
      return 0;
    });

    if (!despesasCapitalItem) {
      despesasCapitalItem = {
        title1: "",
        title2: "Despesas de Capital",
        value1: "",
        value2: "00",
      };
      filteredData.push(despesasCapitalItem);
    }

    const dadosFiltradosSemReserva = filteredData.filter(
      (item) =>
        !(
          (item.codigo && item.codigo.startsWith(reservaCodigoInicial)) ||
          normalizeString(item.title2) === "reserva de contingência"
        )
    );

    const dadosCapital = dadosFiltradosSemReserva.filter(
      (item) => item.codigo && item.codigo.startsWith(capitalCodigoInicial)
    );

    const dadosNaoCapital = dadosFiltradosSemReserva.filter(
      (item) => !(item.codigo && item.codigo.startsWith(capitalCodigoInicial))
    );

    return [...dadosNaoCapital, ...dadosCapital, despesasCapitalItem].filter(
      Boolean
    );
  };

  const dadosFiltrados1 = getReceita();
  const dadosFiltrados2 = getDespesa();

  const dadosCombinados = [];
  let indexDados1 = 0;
  let indexDados2 = 0;
  let pauseData1 = false;
  let pauseData2 = false;

  while (
    indexDados1 < dadosFiltrados1.length - 1 ||
    indexDados2 < dadosFiltrados2.length - 1
  ) {
    let dados1 = {};
    let dados2 = {};

    if (!pauseData1 && dadosFiltrados1[indexDados1]) {
      dados1 = dadosFiltrados1[indexDados1];
    }

    if (!pauseData2 && dadosFiltrados2[indexDados2]) {
      dados2 = dadosFiltrados2[indexDados2];
    }

    // Pausa quando encontra "despesas de capital" ou "receitas de capital"
    if (normalizeString(dados2.title2) === "despesas de capital") {
      pauseData2 = true;
    }

    if (normalizeString(dados1.title1) === "receitas de capital") {
      pauseData1 = true;
    }

    // Despausa e avança os índices se encontrar os pares esperados
    if (
      (pauseData2 &&
        normalizeString(dados1.title1) === "receitas de capital") ||
      (pauseData1 && normalizeString(dados2.title2) === "despesas de capital")
    ) {
      pauseData2 = false;
      pauseData1 = false;

      if (dadosFiltrados2[indexDados2]) {
        dados2 = dadosFiltrados2[indexDados2];
        indexDados2++;
      }

      if (dadosFiltrados1[indexDados1]) {
        dados1 = dadosFiltrados1[indexDados1];
        indexDados1++;
      }
    } else {
      // Avança os índices caso não esteja pausado
      if (!pauseData2) {
        indexDados2++;
      }
      if (!pauseData1) {
        indexDados1++;
      }
    }

    // Adiciona os dados combinados ao array
    dadosCombinados.push({
      title1: pauseData1 ? "" : dados1.title1 || "",
      title2: pauseData2 ? "" : dados2.title2 || "",
      value1: pauseData1 ? "" : dados1.value1 || "",
      value2: pauseData2 ? "" : dados2.value2 || "",
    });
  }

  const index = dadosCombinados.findIndex(
    (item) =>
      normalizeString(item.title1) === "receitas de capital" &&
      normalizeString(item.title2) === "despesas de capital"
  );

  if (index !== -1) {
    let superavit = null;
    let deficit = null;
    if (valorReceitasCorrentes - totalDeducao !== valorDespesasCorrentes) {
      if (valorReceitasCorrentes - totalDeducao > valorDespesasCorrentes) {
        superavit =
          valorReceitasCorrentes - valorDespesasCorrentes - totalDeducao;
      } else {
        deficit =
          valorDespesasCorrentes - totalDeducao - valorReceitasCorrentes;
      }
    }
    const novosObjetos = [
      {
        title1: deficit ? "Déficit" : "",
        title2: superavit ? "Superávit" : "",
        value1: deficit ? deficit : "",
        value2: superavit ? superavit : "",
      },
      {
        title1: "Total das Receitas Correntes",
        title2: "Total das Despesas Correntes",
        value1:
          valorReceitasCorrentes - totalDeducao + (deficit && deficit) || "",
        value2: valorDespesasCorrentes + (superavit && superavit) || "",
      },
      {
        title1: "\u00A0",
        title2: "\u00A0",
        value1: "",
        value2: "",
      },
    ];

    dadosCombinados.splice(index, 0, ...novosObjetos);
  }

  let superavit = null;
  let deficit = null;
  if (valorReceitasCapital !== valorDespesasCapital) {
    if (valorReceitasCapital > valorDespesasCapital) {
      superavit = valorReceitasCapital - valorDespesasCapital;
    } else {
      deficit = valorDespesasCapital - valorReceitasCapital;
    }
  }
  dadosCombinados.push({
    title1: deficit ? "Déficit" : "",
    title2: superavit ? "Superávit" : "",
    value1: deficit ? deficit : "",
    value2: superavit ? superavit : "",
  });

  dadosCombinados.push(
    {
      title1: "Total das Receitas de Capital ",
      title2: "Total das Despesas de Capital ",
      value1: valorReceitasCapital + (deficit && deficit) || "",
      value2: valorDespesasCapital + (superavit && superavit) || "",
    },
    {
      title1: "\u00A0",
      title2: "\u00A0",
      value1: "",
      value2: "",
    }
  );

  const valorReservaContingencia = parseFloat(
    dataNatDespRelatorio?.naturezasConsolidadas?.find(
      (item) => normalizeString(item.nome) === "reserva de contingencia"
    )?.valor
  );

  let summaryJson = [
    {
      title1: "Receitas Correntes",
      title2: "Despesas Correntes",
      value1: valorReceitasCorrentes,
      value2: valorDespesasCorrentes,
    },
    {
      title1: "Receitas de Capital",
      title2: "Despesas de Capital",
      value1: valorReceitasCapital,
      value2: valorDespesasCapital,
    },
    {
      title1: "Dedução da Receita",
      title2: "Reserva de Contingência",
      value1: totalDeducao,
      value2: valorReservaContingencia,
    },
    {
      title1: "TOTAL",
      title2: "TOTAL",
      value1: valorReceitasCorrentes + valorReceitasCapital - totalDeducao,
      value2:
        valorDespesasCorrentes +
        valorDespesasCapital +
        valorReservaContingencia,
    },
  ];

  dadosCombinados.forEach((item) => {
    summaryJson.forEach((summaryItem) => {
      const title1Clean = item.title1 && item.title1.replace(/\./g, "");
      if (
        summaryItem.title1 === title1Clean &&
        summaryItem.title2 === item.title2
      ) {
        summaryItem.value1 = item.value1;
        summaryItem.value2 = item.value2;
      }
    });
  });

  return {
    data: dadosCombinados,
    summary: summaryJson,
  };
};
